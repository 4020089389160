* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-family: "Open Sans";
  overflow-x: hidden;
}

/* Disclaimer Container */
.disclaimer-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

/* Disclaimer Title */
.disclaimer-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Disclaimer Item */
.disclaimer-item {
  margin-bottom: 15px;
}

/* Disclaimer Item Number */
.disclaimer-item-number {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}

/* Disclaimer Item Content */
.disclaimer-item-content {
  font-size: 16px;
  color: #555;
}

/* home page css */

.chatbot-container .rsc-container{
  height: 680px !important;
  background: #fff !important;
}

.rsc-content{
  height: 538px !important;
}

.rsc-footer input{
  background: #EBF3F9 !important;
  opacity: 1;
}

.fancy-card-chatbot{
  box-shadow: 0px 3px 6px #00000084;
  border-radius: 10px;
  z-index: 1;
}

.overlayText:before {
  left: 0;
  content: "What is bidboli?";
  color: #000;
  width: 100%;
  top: -65px;
  font-size: 64px;
  opacity: 0.09;
  line-height: 2.5;
  font-weight: 700;
  position: absolute;
}

.carousel.carousel-slider{
  overflow: visible !important;
}


.homeText {
  font-size: 24px;
  line-height: 35px;
  font-weight: 400;
  color: #474E58;
}

.homeText2 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 400;
  color: #474E58;
}
.bannerHeading{
  font-size: 64px;
}
.bannerHeading2{
  font-size: 52px ;
}
.bannerHeading3{
  font-size: 21px !important;
  color: #474E58;
}

.homeHeadingText {
  font-size: 34px;
  line-height: 35px;
}

.know-more {
  height: 45px;
  padding: 0 20px;
  font-size: 0.9rem;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.carousel-status {
  display: none;
}

.control-prev {
  height: 50px;
  width: 50px;
  top: 256px !important;
  border-radius: 50%;
}

.control-next {
  height: 50px;
  width: 50px;
  top: 256px !important;
  border-radius: 50%;
}

.control-dots .dot {
  /* border: 1.5px solid #002b5d !important;  */
  background-color: #002b5d !important;
}

.knowMoreBtn {
  margin-top: -20px;
}

.fontFamily {
  font-family: Open Sans;
}

.homeBannerImg {
  position: absolute;
  top: 98px;
  left: 109px;
  z-index: -1;
}

.homeBanner {
  /* background-image: url("../../public/assets/images/homeBanner.svg"); */
  width: 100%;
  /* min-height: 57vh; */
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.know-more:hover {
  transition: 0.5s;
  background-color: #F2875C;
  color: #fff;
}

.block-bidboli-head {
  min-width: 60px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #0b2a5a;
  border-radius: 50%;
  margin-right: 8px;
}

.block-bidboli-head img {
  width: 27px;
  height: 27px;
}

.block-bidboli {
  min-width: 60px;
  text-align: center;
  margin-right: 8px;
}

.bidboli-banner-section h2 {
  font-family: "Montserrat", sans-serif;
}

.bidboli-banner-section p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 26px;
}

.bidboli-banner-section span {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
}
.accept-invitation-card{
  padding: 50px;
}
.accept-invitation-card p{
  font-size: 20px;
}

.auction-btnn {
  /* background-color:rgb(35, 50, 57); */
  color: #000000 !important;
  /* font-size: 16px !important; */
  /* font-weight: 300; */
  padding: 2px 10px 2px 0px;
  width: fit-content;
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 0;
  font-family: "Montserrat", sans-serif;
  position: relative;
  border-right: 1px solid rgba(22, 21, 21, 0.2);
  /* clip-path: polygon(0 0%, 117% 0%, 93% 100%, 0% 100%, 0% 0%); */
}

.auction-btnn img {
  margin: 0 5px;
}

/* .auction-btnn:before {
  content: "";
  width: 37px;
  height: 30px;
  background-color: #fff;
  right: 0;
  bottom: 0;
  position: absolute;
} */

.fancy-card-accordian {
  border-radius: 8px !important;
  box-shadow: 0 1px 6px #dbdbdb;
  border: 1px solid #e4e5e7 !important;
  margin-bottom: 15px;
}

.accordian-header-lots {
  height: 50px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid #e9e9e9;
}
.accordian-header-lots2 {
  height: 50px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid #e9e9e9;
}

.delete-lot-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 26px;
  line-height: 24px !important;
  width: 26px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 6px #dbdbdb;
  border: 1px solid #dbdbdb;
}

.btn-subscribe {
  background: #F2875C;
  border-color: #F2875C;
  color: #fff;
  width: 170px;
  height: 50px;
  border: 0px;
  border-radius: 0 5px 5px 0;
  font-weight: 700;
  font-size: 1rem;
  outline: 0;
  box-shadow: none;
}

.btn-subscribe:hover {
  transition: 0.5s;
  background: #F2875C;
}
.service-pera{
  height: 200px;
}
.services-img{
  height: 250px;
}
.looking_service_sec{
  height: 75px;
}

/* end home page css */

/* event cards css */

.eventbox {
  transition: 0.5s;
}

.fancy-card {
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.fancy-card2 {
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #89959e69;
}

.eventCardImage {
  max-height: 200px;
  object-fit: contain;
  min-height: 200px;
}

.deadline {
  /* background-color: #c30; */
  border: none;
  border-radius: 0 25px 0 0;
  bottom: 8px;
  color: #fff;
  left: 0;
  padding: 3px 20px 3px 10px;
}

.deadline,
.likebox {
  position: absolute;
}

.wishlister2 {
  z-index: 0;
  right: 3px;
  top: 10px;
  background-color: #ffffffe0;
  display: inline-block;
  padding: 10px;
  min-width: 38px;
  height: 38px;
  line-height: 22px;
  border-radius: 17px;
  text-align: center;
  font-size: 18px;
  color: #484848 !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

.eventName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.event_ml_left {
  margin-left: -3px;
}

.event_ml_right {
  margin-left: 3px;
}

/* end event cards css */

/* buy page css start */

.bannertext {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  left: 40px;
  position: absolute;
  bottom: 0;
}

.buyerText {
  width: 95%;
}

.buyerText p {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: normal;
  /* color: #fff; */
  font-weight: 400;
}

.bannerSideImg {
  width: 100%;
  height: 100%;
}

.bannerSideImg img {
  padding: 10px 0;
  /* height: 750px; */
}

.bannerSideImg2 img {
  padding: 21px 20px;
  /* height: 750px; */
}

.buyBanner {
  background-image: url("../../public/assets/images/Group 3631.png");
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  /* min-height:76vh */
  /* background-size: cover; */
}

.sellBanner {
  background-image: url("../../public/assets/images/Group 3632.png");
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 63vh;
  /* background-size: cover; */
}

.blueBg {
  background-image: url("../../public/assets/images/blueBG.svg");
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 80px;
}

.whiteBg {
  /* background-image: url("../../public/assets/images/blueBG.svg"); */
  width: 100%;
  min-height: 300px;
  /* background-repeat: no-repeat;
    background-position: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #89959e;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 80px;
}

.whiteBg p {
  margin-top: 15px;
}

.greenBg {
  background-image: url("../../public/assets/images/greenBG.svg");
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 80px;
}

.greenBg p {
  margin-top: 15px;
}

.blueBg p {
  margin-top: 15px;
}

.img-cont-wrap {
  position: relative;
}

.blueBgrightIcon {
  position: absolute;
  top: 50%;
  right: -5%;
}
.blueBgLeftIcon {
  position: absolute;
  top: 50%;
  right: -5%;
  transform: rotate(180deg);
}

.blueBgsideIcon {
  position: absolute;
  right: 45%;
  bottom: -50px;
  transform: rotate(90deg);
}

@media (max-width: 999px) {
  .bannertext {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    left: 20px;
    position: absolute;
    bottom: 0;
  }
  .blueBgrightIcon {
    position: absolute;
    top: 363px;
    right: 45%;
    transform: rotate(90deg);
  }
  .blueBgLeftIcon {
    position: absolute;
    top: 363px;
    right: 45%;
    transform: rotate(90deg);
  }
  .blueBgsideIcon {
    position: absolute;
    right: 45%;
    bottom: -50px;
    transform: rotate(90deg);
  }

  .bannerSideImg img {
    padding: 50px 0;
    height: 350px;
    margin-bottom: 0;
  }

  .buyerText p {
    font-size: 17px;
    /* color: #fff; */
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .buyerText p {
    font-size: 16px;
    /* color: #fff; */
    font-weight: 500;
  }

  .bannertext {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    left: 20px;
    position: absolute;
    bottom: 0;
  }

  .bannerSideImg {
    width: 100%;
    height: 100%;
  }

  .bannerSideImg img {
    padding: 30px 0;
    height: 250px;
    margin-bottom: 130px;
  }
}

/* buy page css end */

/* faq page */
.faq_title {
  color: #002b5d;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.collapse-header-wrapper {
  border: 1px solid #c2d6e2;
  font-family: "Open Sans";
  margin-bottom: 7px !important;
}

.collapse-header-wrapper:nth-child(odd) {
  /* background-color: #f9fafe; */
}

.collapse-header-wrapper p {
  font-family: "Montserrat", sans-serif;
  color: #666;
}

/* end faq page */

/* contact us page */
.addresText {
  color: #003879;
  text-align: center;
  width: 55%;
  margin: auto;
  line-height: 30px;
}

/* end contact us page */

/* service page */
.h-serviceSelect {
  height: 22px;
}

.otp-box-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 40px;
}

.otp-box-wrapper input {
  cursor: pointer;
  border: 1px solid #e4e5e7 !important;
  border-radius: 8px !important;
  color: #222325 !important;
  font-weight: 400;
  min-height: 50px;
  box-shadow: 0px 1px 6px #dbdbdb;
  position: absolute;
  right: 0;
  width: 100%;
  padding: 0 10px;
  outline: 0px;
}

.otp-box-wrapper button {
  height: 46px !important;
  position: absolute;
  right: 2px;
  border-radius: 7px;
  padding: 0;
  top: 2px;
  min-width: 150px !important;
}

.service-capsule {
  background: #EBF3F9;
  text-align: center;
  padding: 5px 15px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 24px;
}

.service-text {
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}

.drop-service-btn {
  width: 50%;
  border: 1px solid #484848;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  margin-top: 25px;
  color: #484848;
  cursor: pointer;
}

.fixed-drop-service {
  position: unset !important;
  padding: 2px !important;
  transform: translate(0%, 3%) !important;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.239);
  padding: 0;
  top: 0% !important;
  width: 500px;
}

.service-card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-card-img {
  height: 145px;
  line-height: 145px;
  border-radius: 15px 0px 0 15px;
}

/* end service page */

.forM-g {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid #000;
  line-height: 1.6;
}

.forM-title,
.forM-subtitle,
.forM-corporate-debtor,
.forM-regulation {
  text-align: center;
  margin: 10px 0;
}

.forM-title {
  font-size: 24px;
  font-weight: bold;
}

.forM-subtitle {
  font-size: 20px;
}

.forM-corporate-debtor {
  font-size: 18px;
}

.forM-regulation {
  font-size: 14px;
}

.forM-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.forM-table td {
  padding: 10px;
  border: 1px solid #000;
}

.forM-label {
  font-weight: bold;
  width: 50%;
}

.forM-data {
  width: 50%;
}

.forM-footer {
  margin-top: 20px;
}

.forM-footer p {
  margin: 5px 0;
}

/* all events page */

.card-video iframe {
  border-radius: 15px;
  height: 100%;
  width: 100%;
}

.eventCardBody{
  padding: 0px 0px 0px 25px;
}

.card-video iframe {
  border-radius: 15px;
}

.card-video video {
  top: 0 !important;
}

.card-image img {
  object-fit: contain;
  border-radius: 15px;
  height: 10rem;
  width: 100%; 
}
.Ratingg span span{
  font-size: 30px;
}

.cardLocations {
  max-height: 170px;
  overflow: hidden;
  min-height: 170px;
  height: 170px;
}

.cardLocations2 {
  max-height: 105px;
  overflow: hidden;
  min-height: 105px;
  height: 105px;

}

.cardDates {
  max-height: 130px;
  overflow: hidden;
  min-height: 130px;
  height: 130px;
}

.cardContent {
  border-radius: 15px;
  min-height: 178px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.middleBox:after {
  content: "";
  height: 77%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 15px;
  background-color: #c3c3c3;
}

.wishlister {
  z-index: 0;
  right: 21px;
  top: 6px;
  background-color: #ffffffe0;
  display: inline-block;
  padding: 11px 9px 11px 16px;
  height: 40px;
  border-radius: 21px;
  font-size: 13px;
  font-weight: 500;
  font-family: Open Sans;
  color: #484848 !important;
  /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1); */
}

.event-discription {
  overflow: hidden;
  font-family: Open Sans;
  text-align: justify;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.event-discription2 {
  overflow: hidden;
  font-family: Open Sans;
  text-align: justify;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.wishlister {
  z-index: 1 !important;
}

.sidebar label {
  margin-left: 8px !important;
  margin-top: 8px !important;
}

.showAll {
  font-size: 15px;
  height: 31px;
}

.event-filter-sidebar {
  min-height: 650px;
  height: min-content;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

/* end all events page */

/* profile pages */

.service-box {
  padding: 0 15px;
  margin-bottom: 15px;
}

.editIcon {
  align-items: center;
  background: #F2875C;
  border-radius: 0 0 0 10px;
  color: #fff;
  display: flex;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 27px;
}

.FileIcon {
  /* cursor: pointer; */
  align-items: center;
  /* background: #F2875C; */
  border-radius: 0 0 0 10px;
  /* color: #fff; */
  display: flex;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 27px;
}

.lite_blue_box {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.file-upload1 input[type="file"] {
  cursor: pointer;
  height: 27px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  width: 27px;
  /* z-index: 1; */
}

.file-upload2 {
  position: relative;
}

.file-upload2 input[type="file"] {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  font-size: 0;
  /* z-index: 1; */
}

.sidebarBtn {
  background: #fff;
  border-color: #fff;
  color: #89959e;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.3s ease !important;
}

.sidebarActiveBtn {
  border-color: #F2875C;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.3s ease !important;
}

.sidebarBtn:hover {
  background-color: #e9ecef;
}

.sidebarActiveBtn,
.sidebarActiveBtn:hover {
  background: #F2875C;
  color: #fff;
}

.sidebarActiveBtn i {
  color: #fff;
}

.profile-secc {
  /* margin: 20px; */
  padding: 0 20px;
}

.phonecode-dropdown ul {
  width: 250px;
}

.phonecode-dropdown button {
  border: 0;
  outline: 0;
}

/* end profile pages */

/* create event */
.create_event_tabs {
  width: 100%;
}

.create_event_tablinks {
  width: 100%;
}

.create_event_tabs button.active {
  border-bottom: 7px solid #F2875C;
  color: #F2875C;
  font-weight: 700;
}

.create-event-bottom-btns {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.create_event_tabs button {
  border: none;
  border-bottom: 7px solid #c6d5e1;
  cursor: unset;
  float: left;
  font-size: 17px;
  outline: none;
  padding-bottom: 10px;
  transition: 0.3s;
}

.create_event_tabs button .create_no_btn {
  background-color: #c6d5e1;
  border-radius: 50%;
  padding: 10px 20px;
  border: 4px solid #fff;
}

.create_event_tabs button {
  background-color: transparent;
}

.create_event_tabs button .create_no_btn,
.oran_but {
  color: #89959E;
}

.create_event_tabs button.active .create_no_btn {
  background-color: #F2875C;
}

.create_event_tabs button.active .create_no_btn {
  color: #fff;
}

.create_event_tabs button.active h5 {
  color: #F2875C;
}

.applicant_tracker_tabs button.active {
  border-bottom: 7px solid #F2875C;
  color: #F2875C;
  font-weight: 700;
}

.applicant_tracker_tabs button.active h5 {
  color: #F2875C;
  font-weight: 700;
}

.applicant_tracker_tabs button {
  border: none;
  border-bottom: 7px solid #c6d5e1;
  cursor: pointer;
  float: left;
  font-size: 17px;
  outline: none;
  padding-bottom: 10px;
  transition: 0.3s;
}

.applicant_tracker_tabs h5 {
  color: #6c757d;
}

.applicant_tracker_tabs button .create_no_btn {
  background-color: #c6d5e1;
  border-radius: 50%;
  padding: 10px 20px;
}

.applicant_tracker_tabs button {
  /* background-color: #ffffff; */
}

.applicant_tracker_tabs button .create_no_btn,
.oran_but {
  color: #6c757d;
  font-weight: 700;
}

.applicant_tracker_tabs button.active .create_no_btn {
  background-color: #F2875C;
}

.applicant_tracker_tabs button.active .create_no_btn {
  color: #fff;
}

.gallery-item-img img,
.gallery-item-img iframe {
  width: 100%;
  height: 215px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 12px #eae8e8;
  border: 1px solid #707070;
}

.file-upload {
  background: #cdd5db;
  color: #fff;
  height: 173px;
  width: 100%;
  border: 1px dashed;
  border-radius: 4px;
  padding: 20px;
  align-items: center;
  display: flex;
}

.uploader-box {
  width: 100%;
  height: 45px;
  border: 1px solid #707070;
  border-radius: 8px;
  background: #fff;
  padding: 1px;
  display: flex;
  align-items: center;
}

.uploader-btn {
  background: #F2875C;
  border: 0;
  outline: 0;
  width: 150px;
  height: 100%;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.close-icon-gallery {
  position: absolute;
  top: -7px;
  right: -10px;
  font-size: 20px;
  color: #F2875C;
  background: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.file-upload input[type="file"] {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  font-size: 0;
  width: 100%;
}

.file-upload h4 {
  color: #8793a4;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.full-img-div {
  background: #cccccc6b;
  padding: 15px;
  position: relative;
  max-height: 350px;
  min-height: 350px;
  display: flex;
  text-align: center;
}

.delete-full-img {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
}

.asset-gallery-bg {
  background: #ccc;
  cursor: pointer;
  padding: 0 !important;
}

.asset-gallery-bg img {
  height: 60px;
  object-fit: contain;
  padding: 2px;
  width: 100%;
}

.spinner-gallery {
  background: #ccc;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assetIcons-div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}

.text-iconAsset {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
}

.asset_icon_wrapper {
  text-align: center;
  width: 125px;
  margin: 5px 12px;
  display: inline-block;
  vertical-align: bottom;
}

.fullshowimg {
  background: #ccc;
  padding: 15px;
  max-height: 350px;
  min-height: 350px;
  display: flex;
  text-align: center;
}

.showimagess {
  background: #ccc;
  margin: 10px 2px;
  padding: 0;
}

.showimagess img {
  height: 50px;
  object-fit: contain;
}

.autoCompleteINput .wrapper {
  border: 1px solid #e4e5e7 !important;
  border-radius: 8px !important;
  color: #222325 !important;
  font-weight: 400;
  min-height: 45px;
  outline: 0;
  box-shadow: 0 1px 6px #dbdbdb;
}

/* .autoCompleteINput .wrapper>div{
  flex-direction: row-reverse;
}

.autoCompleteINput .search-icon{
  margin: 0 10px;
} */

/* end creat event */

/* event details */
.asset-gallery-bg1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 650px;
}

.event_details_tabs .MuiTabs-indicator {
  background-color: none;
  height: 0px;
}

.event_details_tabs {
  border-bottom: 3px solid #484848;
}

.event_details_tabs .Mui-selected {
  background-color: #484848 !important;
  color: #ffff;
  border-radius: 10px 10px 0px 0px;
}

.asset-gallery-bg1 img {
  width: 100%;
  max-height: 95px;
  align-self: stretch;
  border: 1px solid #b4b4b4;
}

.full-img-div1 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-height: 400px;
  min-height: 400px;
  max-width: 650px;
}

.full-img-div1 img {
  max-height: 400px;
  align-self: stretch;
  border: 1px solid #b4b4b4;
}

/* end event details */

/* event dashboards */
.dashboardCards {
  background: #fff;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.biddersCard {
  box-shadow: 0px 3px 6px #ff440040;
  border: 1px solid #ff4400;
  border-radius: 5px;
  position: relative;
  opacity: 1;
  font-family: Open sans;
}

.handIcon {
  transform: scaleX(-1);
  font-size: xx-large;
  position: absolute;
  right: 20px;
  top: 25px;
}

.checkIcon {
  font-size: xx-large;
  position: absolute;
  right: 20px;
  top: 20px;
}

.assetsDatepicker .react-datepicker-wrapper {
  width: 100%;
}

.event-headers {
  background: #484848 0 0 no-repeat padding-box;
  border-radius: 15px 15px 0 0;
  color: #fff;
}

.event-headers h6 {
  margin: 0;
  padding: 15px;
}

.event-selected-wrapper {
  display: flex;
  padding: 15px;
}

.image-of-event {
  max-height: 150px;
  max-width: 150px;
  min-width: 150px;
}

.image-of-event img {
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.event-selected-details {
  padding: 0 15px;
}

.event-selected-description,
.event-selected-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.event-selected-title {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  font-family: sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
}

.event-selected-description {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  font-family: serif;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.events-dropdownList {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.239);
  padding: 0;
  position: absolute;
  top: 0% !important;
  -webkit-transform: translate(0%, 6%) !important;
  transform: translate(0%, 7%) !important;
  width: 500px;
}

.published-dropdownList {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.239);
  padding: 0;
  position: absolute;
  /* top: 20px !important;
  left :609px !important; */
  -webkit-transform: translate(0%, 6%) !important;
  transform: translate(149%, 10%) !important;
  width: 500px;
}

.eventChangebtn {
  border-radius: 5px !important;
  box-shadow: none !important;
  float: right;
  font-size: 15px;
  font-weight: 400;
  height: 35px;
  line-height: 0px;
  margin-top: 20px;
  min-width: 140px !important;
  outline: 0;
  background: #fff;
  border: 1px solid #484848;
  color: #484848;
  transition: 0.5s;
}

.eventChangebtn:hover,
.eventChangebtn:focus {
  background-color: #484848;
  color: #fff;
}

.drop-head {
  background: #484848;
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
}

.drop-head h5 {
  color: #fff;
  font-weight: 600;
  margin: 0;
}

.drop-list-content {
  height: 450px;
  margin: 20px;
  overflow-y: auto;
}

.published-drop-list-content {
  height: 672px;
  margin: 20px;
  overflow-y: auto;
}

.published-drop-list-content::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.published-drop-list-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.published-drop-list-content::-webkit-scrollbar-thumb {
  background: #48484854;
}

/* Handle on hover */
.published-drop-list-content::-webkit-scrollbar-thumb:hover {
  background: #48484854;
}

.discription {
  min-height: 22px;
  max-height: 95px;
}

.discriptionScroll {
  min-height: 22px;
  max-height: 95px;
  overflow-y: auto;
}

.discriptionScroll::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.discriptionScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.discriptionScroll::-webkit-scrollbar-thumb {
  background: #48484854;
}

/* Handle on hover */
.discriptionScroll::-webkit-scrollbar-thumb:hover {
  background: #48484854;
}

.active-border-list {
  border: 1px solid #F2875C;
}

.dropdownList {
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 10px;
  transition: 0.5s;
}

.dropdownList:hover {
  border: 1px solid #f40 !important;
}

.dropdownImg {
  padding: 0;
  text-align: center;
}

.dropdownImg img {
  border-radius: 10px;
  max-height: 80px;
  min-height: 80px;
  object-fit: contain;
  width: 100%;
}

.drop-description,
.drop-title {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: 600;
  margin-bottom: 5px;
  overflow: hidden;
}

.drop-title {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 15px;
}

.drop-description {
  color: #333;
  font-family: serif;
  font-size: 14px;
}

.drop-date {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: serif;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  overflow: hidden;
}

.drop-list-content::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.drop-list-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.drop-list-content::-webkit-scrollbar-thumb {
  background: #48484854;
}

/* Handle on hover */
.drop-list-content::-webkit-scrollbar-thumb:hover {
  background: #48484854;
}

.dashboardUl {
  margin: 0;
  padding: 15px 0px;
}

.dashboardUl li {
  border-radius: 15px 0 0 15px;
  cursor: pointer;
  padding: 10px 15px;
}

.bg-active,
.bg-active-link,
.bg-active-link2,
.bg-active-link3 {
  border: 1px solid #ff572a;
}

.bg-active-link {
  background-color: #fff;
  border-left-width: 5px;
  border-right: 0;
  color: #f40;
  font-weight: 500;
  margin-right: -25px;
}

.bg-active-link2 {
  background-color: #fff;
  border-left-width: 5px;
  border-right: 0;
  color: #f40;
  font-weight: 500;
  margin-right: -40px;
}

.bg-active-link3 {
  background-color: #fff;
  border-left-width: 5px;
  border-right: 0;
  color: #f40;
  font-weight: 500;
  margin-right: -55px;
}

.border-r {
  border-radius: 15px;
}

.confirm-status-box-wrapper {
  align-items: center;
  display: flex;
  /* height: 65vh; */
}

.confirm-status-box {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 2px #51515178;
  display: flex;
  justify-content: center;
  /* padding: 30px; */
}

/* .confirm-status-box  {
  height: 20px;
} */

.confirm-status-box h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

/* .pending-box {
  background: #ebf3f9;
}

.confirmed-box {
  background: #ccffcc;
}

.denied-box {
  background: #ffcccb;
} */

.pro-imgg-lg {
  box-shadow: 0 1px 5px #ccc;
  height: 92px;
  max-height: 92px;
  min-height: 92px;
  object-fit: contain;
  padding: 3px;
  width: 92px;
}

.pro-img-dataTable {
  box-shadow: 0 1px 5px #ccc;
  height: 40px;
  max-height: 40px;
  min-height: 50px;
  object-fit: contain;
  padding: 3px;
  width: 50px;
}

/* event dashboards */

/* login page */
.min-vh-92 {
  min-height: 92vh;
}

.goBack {
  /* background-color: #fff;
  border: 1px solid #fff; */
  left: 15px;
  position: absolute;
  top: 16px;
  width: 130px;
}

.loginIcons {
  height: 30px;
  width: 30px;
}

.loginIcon {
  width: 50px;
}

.eyeToggle {
  cursor: pointer;
  position: absolute;
  right: 26px;
  z-index: 999;
  top: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.otpInput input {
  background-color: #ebf3f9;
  border: none;
  font-size: 2rem;
  outline: none;
  width: 100% !important;
}

.resendButton {
  background: none;
  border: none;
  color: #F2875C;
}

/* end login page */

/* blog page css */
.blogImage img {
  max-height: 300px;
  width: auto;
  object-fit: contain;
}

/* end blog page css */

/* chat box */

.card_chat {
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08627450980392157);
  border: 1px solid hsla(0, 0%, 43.9%, 0.15);
}

.msg_head {
  position: relative;
  padding: 0.3rem 1rem;
}

.Confirm_head {
  position: relative;
  min-height: 7rem;
}

.online {
  font-size: 0.8rem !important;
}

.card-header {
  border-radius: 6px 6px 0 0 !important;
  border-bottom: none;
  background-color: #5d5b5b;
  color: #ffff;
}

.img_cont,
.user_img {
  height: 51px;
  width: 51px;
}

.img_cont {
  position: relative;
}

.img_cont2,
.user_img {
  height: 75px;
  width: 75px;
}

.img_cont2 {
  position: relative;
}

.checkbox {
  background: green;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
}

.chat_send_secc .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 1;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.card_profile_image {
  height: 100%;
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}

.card_profile_image2 {
  height: 100%;
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}

.user_info,
.user_info2 {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  flex-direction: column;
}

.user_info2 span {
  font-size: 16px;
  font-weight: 600;
}

.status_div.set_status {
  justify-content: flex-end;
  margin-bottom: 5px;
}

.status_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  color: #707070;
  font-size: 14px;
}

.offline_status {
  background: #ff4b2b;
}

.offline_status,
.online_status {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 3px;
}

.msg_card_body {
  overflow-y: auto;
  height: 77vh;
}

.card-footer {
  border-radius: 0 0 6px 6px !important;
  border-top: 0 !important;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.msg_cotainer {
  width: max-content;
  border-radius: 15px;
  background-color: #5d5b5b;
  padding: 12px 15px 8px 15px;
  color: #ffff;
  font-weight: 400;
  margin-top: 15px;
}

.msg_time,
.type_msg {
  position: absolute;
  font-family: "Poppins";
}

.end-chat {
  margin-left: auto;
  background-color: #e4e6eb;
  color: #000;
}

.btn_text_send {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.btn_text_send5 {
  position: absolute;
  cursor: pointer;
  right: 220px;
  top: 10px;
  z-index: 2;
}

/* building blocks css */
table.bb-tables {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.bb-tables th {
  color: #89959e;
  max-width: 300px;
  min-width: 250px;
  vertical-align: text-bottom;
}

.bb-tables tbody tr {
  border: 1px solid #fff;
}

.bb-tables tbody td {
  border-top: 1px solid #c2d6e2;
  border-bottom: 1px solid #c2d6e2;
}

.bb-tables tbody td:first-child {
  border-left: 1px solid #c2d6e2;
}

.bb-tables tbody td:last-child {
  border-right: 1px solid #c2d6e2;
}

/* end building blocks css */

/* event dashboard new seller */

.full-date .react-datepicker-wrapper {
  width: 100%;
}

.verified-text {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 600;
  color: green;
  font-size: 15px;
}

.welcome-dashboard {
  background-image: url("../../public/assets/images/welcome-msg.svg");
  height: 80px;
  text-align: center;
  background-position: right;
  background-repeat: no-repeat;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-dashboard h2 {
  font-family: open-sans;
  color: #484848;
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

.welcome-dashboard h2 span {
  color: #F2875C;
  font-size: 28px;
}

.dashboard-mid {
  background-image: url("../../public/assets/images/undraw_success_factors.svg");
  height: 700px;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}

.dashboard-mid h4 {
  margin: 0 250px 50px 82px;
}

.table-heads {
  font-size: 15px;
  font-weight: 600;
  color: #89959e;
  display: inline-flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.table-rows {
  font-size: 15px;
  font-weight: 300 !important;
  display: inline-flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #c2d6e2;
}

.table-img-sec {
  padding: 0px !important;
}

.table-img-sec img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
}

.table-columns {
  width: 160px;
  padding-right: 10px;
  word-wrap: break-word;
}

.table-columns-id {
  min-width: 70px;
  padding-right: 10px;
  word-wrap: break-word;
}

.table-columns-check {
  width: 30px;
  padding-right: 10px;
  word-wrap: break-word;
}

.table-columns-list {
  width: 110px;
  padding-right: 10px;
  word-wrap: break-word;
}

.table-columns3 {
  width: 185px;
  padding-right: 10px;
  word-wrap: break-word;
}

.table-columns4 {
  width: 180px;
  padding-right: 10px;
  word-wrap: break-word;
}
.table-columns5 {
  width: 190px;
  padding-right: 10px;
  word-wrap: break-word;
}
.privateSaleDatePicker .react-datepicker-wrapper {
  display: flex;
}

.btn-underline {
  color: #002b5d;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.table-columns2 {
  width: 225px;
  padding-right: 10px;
}

.table-columns-inner {
  width: 240px;
  padding-right: 10px;
}

.table-columns-inner2 {
  width: 270px;
  padding-right: 10px;
}

.table-columns-inner23 {
  width: 300px;
  padding-right: 10px;
}

.table-columns-inner3 {
  width: 330px;
  padding-right: 10px;
}

.table-column-width-large {
  min-width: 390px;
  width: auto;
  padding-right: 10px;
}

.table-column-width-large2 {
  min-width: 450px;
  width: auto;
  padding-right: 10px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.table-panel .MuiAccordionSummary-content {
  margin: 0px !important;
  position: relative;
}

.MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: 10px;
}

.BlogImgBox {
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mui-acc::before,
.mui-acc {
  box-shadow: none !important;
  background-color: #fff !important;
}

.scrollable-table {
  width: 100%;
  overflow-x: auto;
}

.scrollable-table::-webkit-scrollbar {
  height: 3px;
}

/* Track */
.scrollable-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollable-table::-webkit-scrollbar-thumb {
  background: #48484854;
}

/* Handle on hover */
.scrollable-table::-webkit-scrollbar-thumb:hover {
  background: #48484854;
}

.remove-bttn {
  color: #fff;
  border: 1px solid;
  padding: 3px 15px;
  margin-top: 5px;
}

.newsRight .wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: transparent;
  border-radius: 8px;
  text-align: center;
}

/* .dropdown {
  width: 12rem;
  height: 1.5rem;
  font-size: 1.3rem;
  padding: 0.6 0.5rem;
  background-color: rgb(171, 226, 226);
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid rgb(197, 197, 190);
}
#options {
  margin: 0.5rem 0;
  margin-left: 0.8rem;
  width: 13.8rem;
  background-color: rgb(250, 248, 248);
  display: none;
  flex-direction: column;
  border-radius: 12px;
  z-index: 99;
}
.labela {
  padding: 0.2rem;
}
.labela:hover {
  background-color: rgb(186, 241, 241);
} */

.newsRight .wrapper .file-upload-sale-botice {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.wrapper .file-upload-sale-botice {
  height: 174px;
  width: 91px;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* background-image: url('../../public/assets/images/icons/png/upload.png'); */
  background-size: contain;
  /* color: #fff; */
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
}

.newsRight .wrapper .file-upload-sale-botice input[type="file"] {
  height: 35px;
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.wrapper-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: transparent;
  border-radius: 8px;
  text-align: center;
}

.wrapper-btn .file-upload-sale-notice {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.wrapper-btn .file-upload-sale-notice {
  /* height: 174px;
  width: 91px; */
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  overflow: hidden;
  /* background-image: url('../../public/assets/images/icons/png/upload.png'); */
  background-size: contain;
  /* color: #fff; */
  background-position: 50%;
  background-repeat: no-repeat;
}

.wrapper-btn .file-upload-sale-notice input[type="file"] {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.mstc-popup {
  height: 500px;
  margin: 20px;
  overflow-y: auto;
}

.pdf-bg {
  background-image: url("../../public/assets/images/bidboli-transparent.png");
  background-position: center;
  background-repeat: no-repeat;
}

.litigation-filter-panel {
  width: 445px;
  position: absolute;
  right: 0;
  background: #fff;
  box-shadow: 0px 3px 12px #ccc;
  min-height: 300px;
  z-index: 9;
}

.border-grey {
  border: 1px solid #ccc;
}

.highlight-box {
  background: rgb(246, 248, 249);
  /* padding: 10px; */
  /* border-radius: 8px !important; */
  box-shadow: 0 1px 6px #dbdbdb;
}

.date_range_picker_mui_custom .MuiStack-root {
  padding: 0;
  border: 1px solid #e4e5e7 !important;
  border-radius: 8px !important;
  color: #222325 !important;
  cursor: pointer;
  font-weight: 400;
  height: 45px;
  outline: 0;
  box-shadow: 0 1px 6px #dbdbdb;
  cursor: pointer;
  overflow: hidden;
}

.date_range_picker_mui_custom .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.json-modal {
  white-space: break-spaces;
  max-height: 500px;
  overflow-y: auto;
}

.json-modal::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.json-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.json-modal::-webkit-scrollbar-thumb {
  background: #48484854;
}

/* Handle on hover */
.json-modal::-webkit-scrollbar-thumb:hover {
  background: #48484854;
}

.dateTimePickerDesign {
  display: flex;
  justify-content: center;
  border: 1px solid #e4e5e7 !important;
  border-radius: 8px !important;
  color: #222325 !important;
  box-shadow: 0 1px 6px #dbdbdb;
  cursor: auto;
  font-weight: 400;
  min-height: 50px;
  outline: 0;
}

.dateTimePickerDesign input {
  border: none;
  padding: 0 10px;
  height: 50px;
}

.dateTimePickerDesign fieldset {
  border: 0 !important;
}

/* temperory csss ============================================================================================================================== */

 @media (min-width: 1800px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px !important;
  }
}

.home-container-main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../public/assets/images/launching.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.email-notify-box-wrapper h2 {
  font-size: 2.6rem;
  font-weight: 700;
  font-family: 'Montserrat';
}

.email-notify-box-wrapper h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #707070;
  margin-bottom: 30px;
}

.email-notify-box-wrapper p {
  font-size: 1.1rem;
  font-weight: 500;
}

.email-notify-box {
  display: flex;
  align-items: center;
  width: 550px;
  height: 50px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 5px;
}

.email-notify-box i {
  color: #707070;
  padding: 0 15px;
  font-size: 18px;
}

.email-notify-box input {
  border: 0 !important;
  outline: 0 !important;
  width: 100%;
  height: 100%;
}

.email-notify-box button {
  width: 200px;
  height: 100%;
  border: 0 !important;
  outline: 0 !important;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  background: #000;
}

.statusResponse {
  background: transparent radial-gradient(closest-side at 50% 50%, #EDEDED 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.statusResponse h4 {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Montserrat';
}

.statusResponse p {
  margin: 20px;
  font-size: 18px;
}

.statusResponse button {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid;
  background: #fff;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  outline: 0 !important;
}

@media only screen and (max-width: 600px) {
  .email-notify-box-wrapper h2 {
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
  }

  .email-notify-box-wrapper h4 {
    color: #707070;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .email-notify-box-wrapper p {
    font-size: 16px;
    font-weight: 500;
  }

  .email-notify-box {
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    height: 50px;
    width: 100%;
  }
} 