/* block loaders */

.loader {
	position: relative;
	top: 40%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100px;
	height: 100px;
	margin: 0 auto;
	transform: rotate(-45deg);
	font-size: 0;
	line-height: 0;
	animation: rotate-loader 5s infinite;
	border: 1px solid #F2875C;
}

.loader .loader-inner {
	position: relative;
	display: inline-block;
	width: 50%;
	height: 50%;
}

.loading {
	background-color: #ededed;
	background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) var(--loading-grey);
	background-size: 200% 100%;
	background-position-x: 180%;
	animation: 1s loading ease-in-out infinite;
}

.loader .loading {
	position: absolute;
	background: #F2875C;
}

.loader .one {
	width: 100%;
	bottom: 0;
	height: 0;
	animation: loading-one 1s infinite;
}

:root {
	--loading-grey: #ededed;
}

.loader .two {
	width: 0;
	height: 100%;
	left: 0;
	animation: loading-two 1s infinite;
	animation-delay: 0.25s;
}

.loader .three {
	width: 0;
	height: 100%;
	right: 0;
	animation: loading-two 1s infinite;
	animation-delay: 0.75s;
}

.loader .four {
	width: 100%;
	top: 0;
	height: 0;
	animation: loading-one 1s infinite;
	animation-delay: 0.5s;
}

@keyframes loading-one {
	0% {
		height: 0;
		opacity: 1;
	}

	12.5% {
		height: 100%;
		opacity: 1;
	}

	50% {
		opacity: 1;
	}

	100% {
		height: 100%;
		opacity: 0;
	}
}

@keyframes loading-two {
	0% {
		width: 0;
		opacity: 1;
	}

	12.5% {
		width: 100%;
		opacity: 1;
	}

	50% {
		opacity: 1;
	}

	100% {
		width: 100%;
		opacity: 0;
	}
}

@keyframes rotate-loader {
	0% {
		transform: rotate(-45deg);
	}

	20% {
		transform: rotate(-45deg);
	}

	25% {
		transform: rotate(-135deg);
	}

	45% {
		transform: rotate(-135deg);
	}

	50% {
		transform: rotate(-225deg);
	}

	70% {
		transform: rotate(-225deg);
	}

	75% {
		transform: rotate(-315deg);
	}

	95% {
		transform: rotate(-315deg);
	}

	100% {
		transform: rotate(-405deg);
	}
}

@media screen and (max-width: 900px) {
	.card-loader {
		width: 100% !important;
	}
}

.card-loader {
	width: 100%;
	/* height: 400px; */
	background-color: #fff;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
	margin-bottom: 16px;
}

.image {
	height: 200px;
}
.image-2{
	height: 300px;
}

.content {
	padding: 2rem 1.8rem;
}

.dashboardModal{
    padding: 15px;
    height: 100px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); */
}

.dashboardModal .square {
	height: 80px;
	width: 80px;
	border-radius: 52%;
	background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
	background-size: 800px 100px;
	animation: wave-squares 2s infinite ease-out;
}

.dashboardModal-right {
	width: 100%;
	padding-left: 15px;
}

.dashboardModal .line {
	height: 10px;
	margin-bottom: 10px;
	width: 100%;
	border-radius: 2px;
	background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
	background-size: 800px 100px;
	animation: wave-lines 2s infinite ease-out;
}

.dashboardModal .line:last-child {
	margin-bottom: 0;
}


.loading .image, .loading .image-2,
.loading h4,
.loading .description,
.loading .title {
	background-color: var(--loading-grey);
	background: linear-gradient(100deg,
			rgba(255, 255, 255, 0) 40%,
			rgba(255, 255, 255, 0.5) 50%,
			rgba(255, 255, 255, 0) 60%) var(--loading-grey);
	background-size: 200% 100%;
	background-position-x: 180%;
	animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
	to {
		background-position-x: -20%;
	}
}

.loading h4 {
	min-height: 1.6rem;
	border-radius: 4px;
	animation-delay: 0.05s;
}

.loading .description {
	min-height: 3rem;
	border-radius: 4px;
	animation-delay: 0.06s;
}

.pill {
	height: 2.5rem;
	border-radius: 1.5rem;
	width: 10rem;
	margin-right: 10px;
}

.loading .title {
	min-height: 2rem;
	border-radius: 4px;
	animation-delay: 0.06s;
}

.block-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.box {
	padding: 15px;
	width: 100%;
	max-width: 100%;
}

.skeleton {
	padding: 15px;
	height: 300px;
	background: #fff;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.skeleton .square {
	height: 250px;
	width: 300px;
	border-radius: 5px;
	background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
	background-size: 800px 100px;
	animation: wave-squares 2s infinite ease-out;
}

.skeleton-right {
	width: 100%;
	padding-left: 15px;
}

.skeleton .line {
	height: 35px;
	margin-bottom: 10px;
	width: 100%;
	border-radius: 2px;
	background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
	background-size: 800px 100px;
	animation: wave-lines 2s infinite ease-out;
}

.skeleton .line:last-child {
	margin-bottom: 0;
}

.h8 {
	height: 20px !important;
}

.h10 {
	height: 10px !important;
}

.h12 {
	height: 12px !important;
}


.h15 {
	height: 15px !important;
}

.h18 {
	height: 50px !important;
}

.h17 {
	height: 40px !important;
}
.h16 {
	height: 100px !important;
}

.h20 {
	height: 20px !important;
}

.h25 {
	height: 25px !important;
}

.w25 {
	width: 25% !important;
}

.w19 {
	width: 19% !important;
}

.w40 {
	width: 40% !important;
}

.w50 {
	width: 50% !important;
}

.w75 {
	width: 75% !important;
}

.m10 {
	margin-bottom: 50px !important;
}

@keyframes wave-lines {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes wave-squares {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes sway {
	0% {
		transform: rotate(8deg);
	}

	50% {
		transform: rotate(-8deg);
	}

	100% {
		transform: rotate(8deg);
	}
}

/* end block loader */